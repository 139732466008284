import { useState } from "react";
import ReactSlider from "react-slider";

const Slider = () => {
  const [currentValue, setCurrentValue] = useState(1); // Start with the default value

  return (
    <div>
      {/* Display the current value */}
      <div>Antwort: {currentValue}</div>
      
      <ReactSlider
        className="customSlider"
        thumbClassName="customSlider-thumb"
        trackClassName="customSlider-track"
        markClassName="customSlider-mark"
        marks={[0,1,2,3,4,5,6,7,8,9,10]}
        min={0}
        max={10}
        defaultValue={0}
        value={currentValue}
        onChange={(value) => setCurrentValue(value)}
        renderMark={(props) => {
          if (props.key < currentValue) {
            props.className = "customSlider-mark customSlider-mark-before";
          } else if (props.key === currentValue) {
            props.className = "customSlider-mark customSlider-mark-active";
          }
          return <span {...props} />;
        }}
      />
      <input
        type="hidden"
        value={currentValue}
        name="moodSliderValue"
      />
    </div>
  );
};

export default Slider;
