import React, { useState } from 'react';
import './BatterySlider.css'; // Make sure this is correctly imported

const BatterySlider = () => {
  const [value, setValue] = useState(0);

  const calculateColor = (value) => {
    // Value is expected to be between 0 and 100
    let red, green;
  
    if (value < 50) {
      // From 0 to 50, we transition from red to yellow
      red = 255;
      green = Math.round(5.1 * value);
    } else {
      // From 50 to 100, we transition from yellow to green
      red = Math.round(510 - 5.1 * value);
      green = 255;
    }
  
    return `rgb(${red},${green},0)`;
  };
  

  return (
    <div className="battery-slider">
      <div className="battery">
        <div
          className="battery-fill"
          style={{
            width: `${value}%`,
            backgroundColor: calculateColor(value),
          }}
        />
        <div className="battery-cap" />
        <div className="battery-percentage" style={{ position: 'absolute', width: '100%', textAlign: 'center' }}>
          {value}%
        </div>
      </div>
      <input
        type="range"
        min="0"
        max="100"
        step="10"
        value={value}
        onChange={(e) => setValue(Number(e.target.value))}
        className="slider"
        name="batterySliderValue"
      />
    </div>
  );
};

export default BatterySlider;
