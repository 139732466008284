import React, { useState } from 'react';
import styles from './Survey.module.css'; // Import the CSS module

const Survey: React.FC<{ onSubmit: (data: any) => void }> = ({ onSubmit }) => {
  const [page, setPage] = useState(() => {
    const savedPage = sessionStorage.getItem('page');
    return savedPage ? Number(savedPage) : 1;
  });
  const [error, setError] = useState<string | null>(null); // Error state

  const stimmeQuestions = [
    'In den meisten Bereichen entspricht mein Leben meinen Idealvorstellungen.',
    'Meine Lebensbedingungen sind ausgezeichnet.',
    'Ich bin mit meinem Leben zufrieden.',
    'Bisher habe ich die wesentlichen Dinge erreicht, die ich mir für mein Leben wünsche.',
    'Wenn ich mein Leben noch einmal leben könnte, würde ich kaum etwas ändern.',
  ];

  const satisfactionQuestions = [
    'mit Ihrer Gesundheit?',
    'mit Ihrem Schlaf?',
    'mit Ihrer Arbeit? (Falls erwerbstätig)?',
    'mit Ihrer Tätigkeit im Haushalt? (Falls im Haushalt tätig)?',
    'mit dem Einkommen Ihres Haushalts?',
    'mit Ihrem persönlichen Einkommen?',
    'mit Ihrer Wohnsituation?',
    'mit Ihrer Freizeit?',
    'mit den vorhandenen Möglichkeiten der Kinderbetreuung? (falls Kinder im Vorschulalter)?',
    'mit Ihrem Familienleben?',
    'mit Ihrer Schul- und Berufsausbildung?',
  ];

  const teilsTeilsQuestions = [
    'Ich bin eher ruhig.',
    'Ich bin einfühlsam, warmherzig.',
    'Ich bin eher unordentlich.',
    'Ich mache mir oft Sorgen.',
    'Ich kann mich für Kunst, Musik und Literatur begeistern.',
    'Ich neige dazu, die Führung zu übernehmen.',
    'Ich bin manchmal unhöflich und schroff.',
    'Ich neige dazu, Aufgaben vor mir herzuschieben.',
    'Ich bin oft deprimiert, niedergeschlagen.',
    'Mich interessieren abstrakte Überlegungen wenig.',
    'Ich bin voller Energie und Tatendrang.',
    'Ich schenke anderen leicht Vertrauen, glaube an das Gute im Menschen.',
    'Ich bin verlässlich, auf mich kann man zählen.',
    'Ich bin ausgeglichen, nicht leicht aus der Ruhe zu bringen.',
    'Ich bin originell, entwickle neue Ideen.',
    'Ich gehe aus mir heraus, bin gesellig.',
    'Andere sind mir eher gleichgültig, egal.',
    'Ich mag es sauber und aufgeräumt.',
    'Ich bleibe auch in stressigen Situationen gelassen.',
    'Ich bin nicht sonderlich kunstinteressiert.',
    'In einer Gruppe überlasse ich lieber anderen die Entscheidung.',
    'Ich begegne anderen mit Respekt.',
    'Ich bleibe an einer Aufgabe dran, bis sie erledigt ist.',
    'Ich bin selbstsicher, mit mir zufrieden.',
    'Es macht mir Spass, gründlich über komplexe Dinge nachzudenken und sie zu verstehen.',
    'Ich bin weniger aktiv und unternehmungslustig als andere.',
    'Ich neige dazu, andere zu kritisieren.',
    'Ich bin manchmal ziemlich nachlässig.',
    'Ich reagiere schnell gereizt oder genervt.',
    'Ich bin nicht besonders einfallsreich.'
  ];

  const stimmeOptions = [
    'stimme überhaupt nicht zu',
    'stimme nicht zu',
    'stimme eher nicht zu',
    'weder/noch',
    'stimme eher zu',
    'stimme zu',
    'stimme völlig zu',
  ];

  const teilsTeilsOptions = [
    'Stimme überhaupt nicht zu',
    'Stimme eher nicht zu',
    'Teils, teils',
    'Stimme eher zu',
    'Stimme voll und ganz zu',
  ];

  type DemographicAnswer = {
    questionID: number;
    answerID: number;
    questionText: string;
    answerText: string;
  }

  type DemographicOption = {
    id: number;
    option: string;
  }

  type DemographicQuestion = {
    id: number;
    category: string;
    question: string;
    options: DemographicOption[];
  }

  const demographicQuestions: DemographicQuestion[] = [
    {
      id: 1,
      category: 'Geschlecht',
      question: 'Welches Geschlecht hast du?',
      options: [
        { id: 1, option: 'Weiblich' },
        { id: 2, option: 'Männlich' },
        { id: 3, option: 'Divers' },
      ],
    },
    {
      id: 2,
      category: 'Bildungsweg',
      question: 'Welche Bildung hast du abgeschlossen? (Mehrfachauswahl möglich)',
      options: [
        { id: 1, option: 'Sekundarschule (7. - 9. Klasse)' },
        { id: 2, option: 'Matura / Berufsmatura / Abitur' },
        { id: 3, option: 'Berufslehre (EFZ, EBA)' },
        { id: 4, option: 'Fachmittelschule (FMS)' },
        { id: 5, option: 'Höhere Fachschule (HF)' },
        { id: 6, option: 'Fachhochschule (FH)' },
        { id: 7, option: 'Universität/ETH' },
        { id: 0, option: 'Andere'}
      ],
    },
    {
      id: 3,
      category: 'Bildungsweg',
      question: 'Welche Bildung machst du gerade? (Mehrfachauswahl möglich)',
      options: [
        { id: 1, option: 'Matura / Berufsmatura / Abitur' },
        { id: 2, option: 'Berufslehre (EFZ, EBA)' },
        { id: 3, option: 'Fachmittelschule (FMS)' },
        { id: 4, option: 'Höhere Fachschule (HF)' },
        { id: 5, option: 'Fachhochschule (FH)' },
        { id: 6, option: 'Universität/ETH' },
        { id: 10, option: 'Keine'},
        { id: 0, option: 'Andere'}
      ],
    },
    {
      id: 4,
      category: 'Haupttätigkeit',
      question: 'Was ist deine Haupttätigkeit?',
      options: [
        { id: 1, option: 'Berufstätig' },
        { id: 2, option: 'StudentIn' },
        {id: 3, option: 'SchülerIn'},
        { id: 3, option: 'Lehrling' },
        { id: 0, option: 'Anderes: Bitte angeben' },
      ],
    },
    {
      id: 5,
      category: 'Nebentätigkeit',
      question: 'Führst du zusätzlich zu deiner Haupttätigkeit noch Nebentätigkeiten aus? ',
      options: [
        {id: 1, option: 'Ja, ich studiere zusätzlich.'},
        {id: 2, option: 'Ja, ich arbeite zusätzlich.'},
        {id: 3, option: 'Ja, ich habe einen Zweitjob.'},
        {id: 0, option: 'Ja, etwas anderes.'},
        {id: 4, option: 'Nein.'},
      ],
    },
    {
      id: 6,
      category: 'Wohnsituation',
      question: 'In welchem Land lebst du?',
      options: [
        { id: 1, option: 'Schweiz' },
        { id: 2, option: 'Deutschland' },
        { id: 3, option: 'Frankreich' },
        { id: 0, option: 'Anderes: Bitte angeben' },
      ],
    },
    {
      id: 7,
      category: 'Wohnsituation',
      question: 'Wie sieht deine aktuelle Wohnsituation aus?',
      options: [
        { id: 1, option: 'Ich wohne mit meinen Eltern / Verwandten.'},
        { id: 2, option: 'Ich wohne alleine.'},
        { id: 3, option: 'Ich wohne mit meinem Partner/meiner Partnerin'},
        { id: 4, option: 'Ich wohne in einer Wohngemeinschaft (WG)'},
        { id: 0, option: 'Andere: Bitte angeben' },
      ],
    },
    {
      id: 8,
      category: 'Wohnsituation',
      question: 'Wo wohnst du gerade?',
      options: [
        { id: 1, option: 'In einer Stadt'},
        { id: 2, option: 'In einer Agglomeration'},
        { id: 3, option: 'Auf dem Land'},
        { id: 0, option: 'Anderer Ort: Bitte angeben'},
      ],
    },
    {
      id: 9,
      category: 'Beziehungsstatus',
      question: 'Wie würdest du deinen Beziehungsstatus beschreiben?',
      options: [
        { id: 1, option: 'Single'},
        { id: 2, option: 'In einer festen Beziehung'},
        { id: 3, option: 'Verheiratet'},
        { id: 0, option: 'Anders: Bitte angeben'},
      ],
    },
    {
      id: 10,
      category: 'Elternschaft',
      question: 'Bist du Elternteil?',
      options: [
        { id: 1, option: 'Ja'},
        { id: 2, option: 'Nein'},
      ],
    }
  ];


  const [stimmeAnswers, setStimmeAnswers] = useState(() => {
    const savedStimmeAnswers = sessionStorage.getItem('stimmeAnswers');
    return savedStimmeAnswers ? JSON.parse(savedStimmeAnswers) : new Array(stimmeQuestions.length).fill('');
  })

  const [satisfactionAnswers, setSatisfactionAnswers] = useState(() => {
    const savedSatisfactionAnswers = sessionStorage.getItem('satisfactionAnswers');
    return savedSatisfactionAnswers ? JSON.parse(savedSatisfactionAnswers) : new Array(satisfactionQuestions.length).fill(null);
  });

  const [teilsTeilsAnswers, setTeilsTeilsAnswers] = useState(() => {
    const savedTeilsTeilsAnswers = sessionStorage.getItem('teilsTeilsAnswers');
    return savedTeilsTeilsAnswers ? JSON.parse(savedTeilsTeilsAnswers) : new Array(teilsTeilsQuestions.length).fill('');
  });

  //do the same for demographicAnswers
  const [demographicAnswers, setDemographicAnswers] = useState(() => {
    const savedDemographicAnswers = sessionStorage.getItem('demographicAnswers');
    return savedDemographicAnswers ? JSON.parse(savedDemographicAnswers) : [];
  });
  
  const [isNoAnswerChecked, setIsNoAnswerChecked] = useState<boolean[]>(new Array(satisfactionQuestions.length).fill(false)); // Track "Keine Angaben" checkboxes
  const [otherAnswers, setOtherAnswers] = useState<{ [key: number]: string }>({}); // To store custom text for "Andere" answers



  console.log(demographicAnswers);

  const handleAnswerChange = (
    questionType: 'stimme' | 'satisfaction' | 'teilsTeils',
    questionIndex: number,
    answer: any
  ) => {
    if (questionType === 'stimme') {
      const updatedAnswers = [...stimmeAnswers];
      updatedAnswers[questionIndex] = answer;
      setStimmeAnswers(updatedAnswers);
    } else if (questionType === 'satisfaction') {
      const updatedAnswers = [...satisfactionAnswers];
      updatedAnswers[questionIndex] = answer;
      setSatisfactionAnswers(updatedAnswers);
    } else if (questionType === 'teilsTeils') {
      const updatedAnswers = [...teilsTeilsAnswers];
      updatedAnswers[questionIndex] = answer;
      setTeilsTeilsAnswers(updatedAnswers);
    }
  };

  const handleSliderChange = (index: number, value: number) => {
    const updatedAnswers = [...satisfactionAnswers];
    updatedAnswers[index] = value;

    const updatedNoAnswer = [...isNoAnswerChecked];
    if (isNoAnswerChecked[index]) {
      // Uncheck "Keine Angaben" if the user interacts with the slider
      updatedNoAnswer[index] = false;
    }

    setIsNoAnswerChecked(updatedNoAnswer);
    setSatisfactionAnswers(updatedAnswers);
  };

  const handleNoAnswerChange = (index: number) => {
    const updatedNoAnswer = [...isNoAnswerChecked];
    updatedNoAnswer[index] = !updatedNoAnswer[index];

    const updatedSatisfactionAnswers = [...satisfactionAnswers];
    if (updatedNoAnswer[index]) {
      updatedSatisfactionAnswers[index] = -1; // Set "Keine Angaben"
    } else {
      updatedSatisfactionAnswers[index] = null; // Set back to no answer (neutral)
    }
    setIsNoAnswerChecked(updatedNoAnswer);
    setSatisfactionAnswers(updatedSatisfactionAnswers);
  };

  const validateAnswers = (answers: (number | null)[]) => answers.every((answer) => answer !== null);

  const validateStringAnswers = (answers: string[]) => answers.every((answer) => answer.trim() !== '');

  const [digitalMediaAnswers, setDigitalMediaAnswers] = useState<number[]>(new Array(4).fill(-1));

  const validateDemographicsAnswers = () => {
    let allAnswered = true;
  
    // Loop through all questions
    demographicQuestions.forEach((question) => {
      // Check if this question has been answered
      const questionAnswered = demographicAnswers.some((answer) => answer.questionID === question.id);
  
      // If the question is answered, we need to verify "Andere" (id: 0) has input, if selected
      if (questionAnswered) {
        const selectedAnswer = demographicAnswers.find((answer) => answer.questionID === question.id);
  
        // If "Andere" was selected (id: 0), check if the custom input is not empty
        if (selectedAnswer?.answerID === 0 && !selectedAnswer.answerText.trim()) {
          allAnswered = false;
        }
      } else {
        // If the question was not answered at all
        allAnswered = false;
      }
    });
  
    return allAnswered;
  };

  const handleNextPage = () => {
    if (page === 1) {
      if (!validateAnswers(satisfactionAnswers)) {
        setError('Bitte beantworten Sie alle Fragen, bevor Sie fortfahren.');
        return;
      }
      if (!validateStringAnswers(stimmeAnswers)) {
        setError('Bitte beantworten Sie alle Fragen, bevor Sie fortfahren.');
        return;
      }
    } else if (page === 3) {
      if (!validateStringAnswers(teilsTeilsAnswers)) {
        setError('Bitte beantworten Sie alle Fragen, bevor Sie fortfahren.');
        return;
      }
    } 
    if (page === 1) {
      sessionStorage.setItem('stimmeAnswers', JSON.stringify(stimmeAnswers));
      sessionStorage.setItem('satisfactionAnswers', JSON.stringify(satisfactionAnswers));
    } else if (page === 3) {
      sessionStorage.setItem('teilsTeilsAnswers', JSON.stringify(teilsTeilsAnswers));
    } else if (page === 4) {
      sessionStorage.setItem('demographicAnswers', JSON.stringify(demographicAnswers));
    }

    if (page === 5 && !validateDigitalMediaAnswers(digitalMediaAnswers)) {
      setError('Bitte beantworten Sie alle Fragen, bevor Sie fortfahren.');
      return;
    }
    setError(null);
    setPage((prevPage) => prevPage + 1);
    sessionStorage.setItem('page', String(page + 1));
    

    // Scroll to top after navigating to the next page
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleSubmit = () => {

    if (!validateDemographicsAnswers()) {
      setError('Bitte beantworten Sie alle Fragen, bevor Sie das Formular abschicken.');
      return;
    }

    setError(null); // Clear error message
    const surveyData = { stimmeAnswers, satisfactionAnswers, teilsTeilsAnswers, demographicAnswers, digitalMediaAnswers };
    onSubmit(surveyData);
  };

  // Handle changes for demographic questions (including Andere)
  const handleDemographicChange = (questionID: number, answerID: number, questionText: string, answerText: string, multipleChoice: boolean = false) => {
    if (multipleChoice) {
      // Multiple choice logic for checkboxes (including Andere)
      const existingAnswer = demographicAnswers.find((answer) => answer.questionID === questionID && answer.answerID === answerID);
      if (existingAnswer) {
        // Deselect if already selected
        setDemographicAnswers((prevAnswers) => prevAnswers.filter((a) => !(a.questionID === questionID && a.answerID === answerID)));
      } else {
        if (answerText === "Keine") {
          // If "Keine" is selected, remove all other answers for this questionID
          setDemographicAnswers((prevAnswers) =>
            prevAnswers
              .filter((a) => a.questionID !== questionID) // Remove all answers for this question
              .concat({ questionID, answerID, questionText, answerText }) // Add only "Keine" answer
          );
        } else {
          // Remove "Keine" answer if it exists
          if (demographicAnswers.some((a) => a.questionID === questionID && a.answerText === "Keine")) {
            setDemographicAnswers((prevAnswers) => prevAnswers.filter((a) => !(a.questionID === questionID && a.answerText === "Keine")));
          }
        }
        // Add the selected option
        const newAnswer = { questionID, answerID, questionText, answerText: answerID === 0 ? '' : answerText }; // Set empty string for Andere initially
        setDemographicAnswers((prevAnswers) => [...prevAnswers, newAnswer]);
      }
    } else {
      // Single choice logic (radio buttons)
      const answer = { questionID, answerID, questionText, answerText: answerID === 0 ? '' : answerText }; // Initialize empty string for Andere
      setDemographicAnswers((prevAnswers) => [...prevAnswers.filter((a) => a.questionID !== questionID), answer]);
    }
  };

  // Handle custom input change for "Andere" (both radio and checkbox cases)
  const handleCustomInputChange = (questionID: number, inputText: string) => {
    setDemographicAnswers((prevAnswers) =>
      prevAnswers.map((answer) => {
        if (answer.questionID === questionID && answer.answerID === 0) {
          return { ...answer, answerText: inputText }; // Update answerText with custom input
        }
        return answer;
      })
    );
  };

  // Group demographic questions by category and display the category only once
  const groupedQuestions = demographicQuestions.reduce<{ [key: string]: typeof demographicQuestions }>((acc, question) => {
    if (!acc[question.category]) {
      acc[question.category] = [];
    }
    acc[question.category].push(question);
    return acc;
  }, {});

  const handleDigitalMediaChange = (index: number, value: number) => {
    const updatedAnswers = [...digitalMediaAnswers];
    updatedAnswers[index] = value;
    setDigitalMediaAnswers(updatedAnswers);
  };

  const validateDigitalMediaAnswers = (answers: number[]) => answers.every((answer) => answer !== -1);



  return (
    <div className={styles.surveyContainer}>
      {page === 1 && (
        <>
          <h4 className={styles.header}>Bitte wählen Sie die Antwortalternative, die für Sie am ehesten zutrifft.</h4>
          {stimmeQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{index+1 + ". " + question}</h3>
              {stimmeOptions.map((option) => (
                <label key={option} className={styles.label}>
                  <input
                    type="radio"
                    name={`stimme-question-${index}`}
                    value={option}
                    checked={stimmeAnswers[index] === option}
                    onChange={() => handleAnswerChange('stimme', index, option)}
                    className={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}

          <h4 className={styles.subheader}>
            Wie zufrieden sind Sie gegenwärtig mit den folgenden Bereichen Ihres Lebens? Wie zufrieden sind Sie...
          </h4>
          {satisfactionQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{index+1 + ". " + question}</h3>
              <div className={styles.sliderContainer}>
                <input
                  type="range"
                  min="0"
                  max="10"
                  value={satisfactionAnswers[index] === null ? 5 : satisfactionAnswers[index]} // Default to middle if "Keine Angaben"
                  onChange={(e) => handleSliderChange(index, Number(e.target.value))} // Uncheck "Keine Angaben" when slider is moved
                  className={`${styles.slider} ${isNoAnswerChecked[index] ? styles.sliderDisabled : ''}`} // Add disabled style
                />
                <div className={styles.sliderValues}>
                  <span>0 – ganz und gar unzufrieden</span>
                  <span>10 – ganz und gar zufrieden</span>
                </div>
                <br></br>
                <label className={styles.noAnswerLabel}>
                  <input
                    type="checkbox"
                    checked={isNoAnswerChecked[index]}
                    onChange={() => handleNoAnswerChange(index)}
                  />
                  Keine Angaben
                </label>
              </div>
              <p className={styles.centerText}>
                Antwort: {satisfactionAnswers[index] === -1 ? 'Keine Angaben' : satisfactionAnswers[index] === null ? 'Keine Antwort' : satisfactionAnswers[index]}
              </p>
            </div>
          ))}

          {error && <div className={styles.errorMessage}>{error}</div>}
          <button type="button" className={styles.button} onClick={handleNextPage}>
            Weiter
          </button>
        </>
      )}

      {page === 2 && (
        <>
        <p>
        Nachstehend finden Sie eine Reihe von Eigenschaften, die auf Sie zutreffen könnten. Würden Sie über sich zum Beispiel sagen, dass Sie gerne Zeit mit anderen Menschen verbringen? Bitte geben Sie für jede der folgenden Aussagen an, inwieweit Sie zustimmen.
        </p>
        <button type="button" className={styles.button} onClick={handleNextPage}>
          Weiter
        </button>
        </>
      )}

      {page === 3 && (
        <>

          {teilsTeilsQuestions.map((question, index) => (
            <div key={index} className={styles.questionBlock}>
              <h3 className={styles.questionTitle}>{index+1 + ". " + question}</h3>
              {teilsTeilsOptions.map((option) => (
                <label key={option} className={styles.label}>
                  <input
                    type="radio"
                    name={`teils-teils-question-${index}`}
                    value={option}
                    checked={teilsTeilsAnswers[index] === option}
                    onChange={() => handleAnswerChange('teilsTeils', index, option)}
                    className={styles.radioInput}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}

          {error && <div className={styles.errorMessage}>{error}</div>}
          <button type="button" className={styles.button} onClick={handleNextPage}>
            Weiter
          </button>
        </>
      )}
      {page === 4 && (
        <>
          <h4 className={styles.header}>Fragenbogen zu demographischen Daten</h4>
          <p>Bitte beantworten Sie die folgenden Fragen zu Ihrer Person.</p>

          {Object.entries(groupedQuestions).map(([category, questions]) => (
            <div key={category} className={styles.questionBlock}>
              <h3>{category}</h3>
              {questions.map((question) => (
                <div key={question.id} className={styles.questionSubBlock}>
                  <h4>{question.id + ". " + question.question}</h4>
                  {question.options.map((option) => (
                    <div key={option.id} className={styles.label}>
                      <label>
                        {/* Conditionally render checkbox for multiple choice and radio for single choice */}
                        {question.category === 'Bildungsweg' ? (
                          <input
                            type="checkbox"
                            name={`demographic-question-${question.id}`}
                            value={option.option}
                            checked={demographicAnswers.some((answer) => answer.questionID === question.id && answer.answerID === option.id)}
                            onChange={() => handleDemographicChange(question.id, option.id, question.question, option.option, true)}
                          />
                        ) : (
                          <input
                            type="radio"
                            name={`demographic-question-${question.id}`}
                            value={option.option}
                            checked={demographicAnswers.some((answer) => answer.questionID === question.id && answer.answerID === option.id)}
                            onChange={() => handleDemographicChange(question.id, option.id, question.question, option.option)}
                          />
                        )}
                        {option.option}
                      </label>

                      {/* Show custom input if "Andere" is selected */}
                      {option.id === 0 && demographicAnswers.some((answer) => answer.questionID === question.id && answer.answerID === 0) && (
                        <input
                          type="text"
                          placeholder="Bitte angeben"
                          value={
                            demographicAnswers.find((answer) => answer.questionID === question.id && answer.answerID === 0)?.answerText || ''
                          }
                          onChange={(e) => handleCustomInputChange(question.id, e.target.value)}
                          className={styles.otherInput}
                        />
                      )}
                    </div>
                  ))}
                </div>
              ))}
            </div>
          ))}
          {error && <div className={styles.errorMessage}>{error}</div>}
          <button type="button" className={styles.button} onClick={handleNextPage}>
            Weiter
          </button>
        </>
      )}
      {page === 5 && (
        <>
        <h4 className={styles.header}>Digitaler Mediennutzungsfragen</h4>

        <div className={styles.questionBlock}>
          <p>
            1) Wie viel Zeit verbringst du durchschnittlich an <strong>einem Wochentag</strong> (Montag bis Freitag) mit der Nutzung digitaler Medien? Dazu zählen alle Tätigkeiten wie zum Beispiel Chatten, Telefonieren, Social Media, Fernsehen, Videospiele, YouTube <strong>sowie</strong> berufliche oder schulische Nutzung.
          </p>
          <input
            type="number"
            value={digitalMediaAnswers[0] === -1 ? '' : digitalMediaAnswers[0]} // Show empty input when value is -1
            onChange={(e) => handleDigitalMediaChange(0, Number(e.target.value))}
            placeholder="Minuten"
            className={styles.input}
          />
          <label className={styles.labelDigital}>Minuten</label>
        </div>

        {/* Hardcoded question 2 */}
        <div className={styles.questionBlock}>
          <p>
            2) Wie viel von der Zeit, die du an <strong>einem Wochentag</strong> (Montag bis Freitag) mit der Nutzung digitaler Medien verbringst, investierst du in produktive Tätigkeiten? Dazu zählen zum Beispiel Nutzung in der Universität/Schule/Arbeit, Lernen, Recherchen oder das Erstellen von Inhalten wie Präsentationen.
          </p>
          <input
            type="number"
            value={digitalMediaAnswers[1] === -1 ? '' : digitalMediaAnswers[1]} // Show empty input when value is -1
            onChange={(e) => handleDigitalMediaChange(1, Number(e.target.value))}
            placeholder="Minuten"
            className={styles.input}
          />
          <label className={styles.labelDigital}>Minuten</label>
        </div>

        {/* Hardcoded question 3 */}
        <div className={styles.questionBlock}>
          <p>
            3) Wie viel Zeit verbringst du durchschnittlich an <strong>einem Wochenend-Tag</strong> (Samstag und Sonntag) mit der Nutzung digitaler Medien insgesamt? Dazu zählen alle Tätigkeiten wie zum Beispiel Chatten, Telefonieren, Social Media, Fernsehen, Videospiele, YouTube <strong>sowie</strong> berufliche oder schulische Nutzung.
          </p>
          <input
            type="number"
            value={digitalMediaAnswers[2] === -1 ? '' : digitalMediaAnswers[2]} // Show empty input when value is -1
            onChange={(e) => handleDigitalMediaChange(2, Number(e.target.value))}
            placeholder="Minuten"
            className={styles.input}
          />
          <label className={styles.labelDigital}>Minuten</label>
        </div>

        {/* Hardcoded question 4 */}
        <div className={styles.questionBlock}>
          <p>
            4) Wie viel von der Zeit, die du an <strong>einem Wochenend-Tag</strong> (Samstag und Sonntag) mit der Nutzung digitaler Medien verbringst, investierst du in produktive Tätigkeiten? Dazu zählen zum Beispiel Nutzung in der Universität/Schule/Arbeit, Lernen, Recherchen oder das Erstellen von Inhalten wie Präsentationen.
          </p>
          <input
            type="number"
            value={digitalMediaAnswers[3] === -1 ? '' : digitalMediaAnswers[3]} // Show empty input when value is -1
            onChange={(e) => handleDigitalMediaChange(3, Number(e.target.value))}
            placeholder="Minuten"
            className={styles.input}
          />
          <label className={styles.labelDigital}>Minuten</label>
        </div>

        {error && <div className={styles.errorMessage}>{error}</div>}
        <button type="button" className={styles.button} onClick={handleSubmit}>
          Submit
        </button>
      </>
    )}
    </div>
  );
};

export default Survey;