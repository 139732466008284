import otherPic from './resources/Other.png';
import truePic from './resources/True-green.png';
import falsePic from './resources/False-red.png';

import image1_1 from './resources/activity/1/1.png';
import image1_2 from './resources/activity/1/2.png';
import image1_3 from './resources/activity/1/3.png';
import image1_4 from './resources/activity/1/4.png';

import image2_1 from './resources/activity/2/1.png';
import image2_2 from './resources/activity/2/2.png';
import image2_3 from './resources/activity/2/3.png';

import image3_1 from './resources/activity/3/1.png';
import image3_2 from './resources/activity/3/2.png';
import image3_3 from './resources/activity/3/3.png';
import image3_4 from './resources/activity/3/4.png';
import image3_5 from './resources/activity/3/5.png';
import image3_6 from './resources/activity/3/6.png';

import image4_1 from './resources/activity/4/1.png';
import image4_2 from './resources/activity/4/2.png';
import image4_3 from './resources/activity/4/3.png';
import image4_4 from './resources/activity/4/4.png';
import image4_5 from './resources/activity/4/5.png';
import image4_6 from './resources/activity/4/6.png';
import image4_7 from './resources/activity/4/7.png';
import image4_8 from './resources/activity/4/8.png';
import image4_9 from './resources/activity/4/9.png';
import image4_10 from './resources/activity/4/10.png';
import image4_11 from './resources/activity/4/11.png';
import image4_12 from './resources/activity/4/12.png';
import image4_13 from './resources/activity/4/13.png';
import image4_14 from './resources/activity/4/14.png';

import image5_1 from './resources/activity/5/1.png';
import image5_2 from './resources/activity/5/2.png';
import image5_3 from './resources/activity/5/3.png';
import image5_4 from './resources/activity/5/4.png';

import image6_1 from './resources/activity/6/1.png';
import image6_2 from './resources/activity/6/2.png';

import image7_1 from './resources/activity/7/1.png';
import image7_2 from './resources/activity/7/2.png';
import image7_3 from './resources/activity/7/3.png';
import image7_4 from './resources/activity/7/4.png';
import image7_5 from './resources/activity/7/5.png';
import image7_6 from './resources/activity/7/6.png';
import image7_7 from './resources/activity/7/7.png';
import image7_8 from './resources/activity/7/8.png';
import image7_9 from './resources/activity/7/9.png';
import image7_10 from './resources/activity/7/10.png';
import image7_11 from './resources/activity/7/11.png';

import image8_1 from './resources/activity/8/1.png';
import image8_2 from './resources/activity/8/2.png';
import image8_3 from './resources/activity/8/3.png';
import image8_4 from './resources/activity/8/4.png';
import image8_5 from './resources/activity/8/5.png';
import image8_6 from './resources/activity/8/6.png';
import image8_7 from './resources/activity/8/7.png';
import image8_8 from './resources/activity/8/8.png';

import image9_1 from './resources/activity/9/1.png';
import image9_2 from './resources/activity/9/2.png';

import image10_1 from './resources/activity/10/1.png';
import image10_2 from './resources/activity/10/2.png';
import image10_3 from './resources/activity/10/3.png';
import image10_4 from './resources/activity/10/4.png';
import image10_5 from './resources/activity/10/5.png';

import image11_1 from './resources/activity/11/1.png';
import image11_2 from './resources/activity/11/2.png';
import image11_3 from './resources/activity/11/3.png';
import image11_4 from './resources/activity/11/4.png';
import image11_5 from './resources/activity/11/5.png';
import image11_6 from './resources/activity/11/6.png';
import image11_7 from './resources/activity/11/7.png';
import image11_8 from './resources/activity/11/8.png';
import image11_9 from './resources/activity/11/9.png';
import image11_10 from './resources/activity/11/10.png';

import image12_1 from './resources/activity/12/1.png';
import image12_2 from './resources/activity/12/2.png';

import image13_1 from './resources/activity/13/1.png';
import image13_2 from './resources/activity/13/2.png';
import image13_3 from './resources/activity/13/3.png';
import image13_4 from './resources/activity/13/4.png';
import image13_5 from './resources/activity/13/5.png';

import image14_1 from './resources/activity/14/1.png';
import image14_2 from './resources/activity/14/2.png';
import image14_3 from './resources/activity/14/3.png';
import image14_4 from './resources/activity/14/4.png';

import image15_1 from './resources/activity/15/1.png';
import image15_2 from './resources/activity/15/2.png';

import image16_1 from './resources/activity/16/1.png';
import image16_2 from './resources/activity/16/2.png';
import image16_3 from './resources/activity/16/3.png';
import image16_4 from './resources/activity/16/4.png';
import image16_5 from './resources/activity/16/5.png';
import image16_6 from './resources/activity/16/6.png';
import image16_7 from './resources/activity/16/7.png';
import image16_8 from './resources/activity/16/8.png';
import image16_9 from './resources/activity/16/9.png';

import image17_1 from './resources/activity/17/1.png';
import image17_2 from './resources/activity/17/2.png';
import image17_3 from './resources/activity/17/3.png';
import image17_4 from './resources/activity/17/4.png';
import image17_5 from './resources/activity/17/5.png';
import image17_6 from './resources/activity/17/6.png';
import image17_7 from './resources/activity/17/7.png';
import image17_8 from './resources/activity/17/8.png';

import image18_1 from './resources/activity/18/1.png';
import image18_2 from './resources/activity/18/2.png';
import image18_3 from './resources/activity/18/3.png';

import image19_1 from './resources/activity/19/1.png';
import image19_2 from './resources/activity/19/2.png';
import image19_3 from './resources/activity/19/3.png';
import image19_4 from './resources/activity/19/4.png';
import image19_5 from './resources/activity/19/5.png';
import image19_6 from './resources/activity/19/6.png';
import image19_7 from './resources/activity/19/7.png';

import image20_1 from './resources/activity/20/1.png';

import image21_1 from './resources/activity/21/1.png';
import image21_2 from './resources/activity/21/2.png';
import image21_3 from './resources/activity/21/3.png';
import image21_4 from './resources/activity/21/4.png';
import image21_5 from './resources/activity/21/5.png';
import image21_6 from './resources/activity/21/6.png';
import image21_7 from './resources/activity/21/7.png';
import image21_8 from './resources/activity/21/8.png';

import person_image1_1 from './resources/person/1/1.png';
import person_image1_2 from './resources/person/1/2.png';
import person_image1_3 from './resources/person/1/3.png';
import person_image1_4 from './resources/person/1/4.png';
import person_image1_5 from './resources/person/1/5.png';
import person_image1_6 from './resources/person/1/6.png';
import person_image1_7 from './resources/person/1/7.png';
import person_image1_8 from './resources/person/1/8.png';

import person_image2_1 from './resources/person/2/1.png';
import person_image2_2 from './resources/person/2/2.png';
import person_image2_3 from './resources/person/2/3.png';
import person_image2_4 from './resources/person/2/4.png';
import person_image2_5 from './resources/person/2/5.png';
import person_image2_6 from './resources/person/2/6.png';
import person_image2_7 from './resources/person/2/7.png';

import person_image3_1 from './resources/person/3/1.png';
import person_image3_2 from './resources/person/3/2.png';
import person_image3_3 from './resources/person/3/3.png';
import person_images3_4 from './resources/person/3/4.png';
import person_image3_5 from './resources/person/3/5.png';
import person_image3_6 from './resources/person/3/6.png';

import person_image4_1 from './resources/person/4/1.png';
import person_image4_2 from './resources/person/4/2.png';
import person_image4_3 from './resources/person/4/3.png';
import person_image4_4 from './resources/person/4/4.png';
import person_image4_5 from './resources/person/4/5.png';

import person_image5_1 from './resources/person/5/1.png';
import person_image5_2 from './resources/person/5/2.png';
import person_image5_3 from './resources/person/5/3.png';

import person_image6_1 from './resources/person/6/1.png';
import person_image6_2 from './resources/person/6/2.png';
import person_image6_3 from './resources/person/6/3.png';
import person_image6_4 from './resources/person/6/4.png';
import person_image6_5 from './resources/person/6/5.png';

import place_image1_1 from './resources/place/1/1.png';
import place_image1_2 from './resources/place/1/2.png';
import place_image1_3 from './resources/place/1/3.png';
import place_image1_4 from './resources/place/1/4.png';
import place_image1_5 from './resources/place/1/5.png';
import place_image1_6 from './resources/place/1/6.png';
import place_image1_7 from './resources/place/1/7.png';
import place_image1_8 from './resources/place/1/8.png';

import place_image2_1 from './resources/place/2/1.png';
import place_image2_2 from './resources/place/2/2.png';
import place_image2_3 from './resources/place/2/3.png';
import place_image2_4 from './resources/place/2/4.png';
import place_image2_5 from './resources/place/2/5.png';

import place_image3_1 from './resources/place/3/1.png';
import place_image3_2 from './resources/place/3/2.png';
import place_image3_3 from './resources/place/3/3.png';
import place_image3_4 from './resources/place/3/4.png';
import place_image3_5 from './resources/place/3/5.png';

import place_image4_1 from './resources/place/4/1.png';
import place_image4_2 from './resources/place/4/2.png';
import place_image4_3 from './resources/place/4/3.png';
import place_image4_4 from './resources/place/4/4.png';
import place_image4_5 from './resources/place/4/5.png';

import place_image5_1 from './resources/place/5/1.png';
import place_image5_2 from './resources/place/5/2.png';
import place_image5_3 from './resources/place/5/3.png';


// Question 0
const text0 = 'Führst du nebenbei weitere Tätigkeiten aus?';
const items0 = [
    {id: 1, text: 'Ja', pictureUrl: truePic},
    {id: 0, text: 'Nein', pictureUrl: falsePic}
];
const question0 = {id: 0, text: text0, items: items0};

// Question 1
const text1 = 'Was machst du gerade?';
const items1 = [
    {id: 1, text: 'Arbeit, Verpflichtung, Ämtli', pictureUrl: image1_1},
    {id: 2, text: 'Freizeit', pictureUrl: image1_2},
    {id: 3, text: 'Essen, Schlafen, Körperpflege', pictureUrl: image1_3},
    {id: 4, text: 'ÖV/ Autofahren', pictureUrl: image1_4}
];
const question1 = {id: 1, text: text1, items: items1};

// Question 2
const text2 = 'Aufgaben/ To Do’s';
const items2 = [
    {id: 1, text: 'Arbeit, Schule', pictureUrl: image2_1},
    {id: 2, text: 'Ämtli (Haushaltspflichten)', pictureUrl: image2_2},
    {id: 3, text: 'Arbeits-, Schulweg', pictureUrl: image2_3}
];
const question2 = {id: 2, text: text2, items: items2};

// Question 3
const text3 = 'Schule/Arbeit';
const items3 = [
    {id: 1, text: 'Lernen', pictureUrl: image3_1},
    {id: 2, text: 'Meeting', pictureUrl: image3_2},
    {id: 3, text: 'Hausaufgaben', pictureUrl: image3_3},
    {id: 4, text: 'Überstunden', pictureUrl: image3_4},
    {id: 5, text: 'Gruppenarbeit', pictureUrl: image3_5},
    {id: 6, text: 'Vor-, Nachbereiten', pictureUrl: image3_6},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question3 = {id: 3, text: text3, items: items3};

// Question 4
const text4 = 'Ämtli (Haushaltspflichten)';
const items4 = [
    {id: 1, text: 'Abwaschen', pictureUrl: image4_1},
    {id: 2, text: 'Einkaufen', pictureUrl: image4_2},
    {id: 3, text: 'Putzen, Aufräumen', pictureUrl: image4_3},
    {id: 4, text: 'Wäsche machen', pictureUrl: image4_4},
    {id: 5, text: 'Tisch decken/abräumen', pictureUrl: image4_5},
    {id: 6, text: 'Kochen/Backen', pictureUrl: image4_6},
    {id: 7, text: 'Haustierpflege', pictureUrl: image4_7},
    {id: 8, text: 'Geschwister hüten', pictureUrl: image4_8},
    {id: 9, text: 'Angehörige pflegen', pictureUrl: image4_9},
    {id: 10, text: 'Abfall entsorgen', pictureUrl: image4_10},
    {id: 11, text: 'Gartenarbeit', pictureUrl: image4_11},
    {id: 12, text: 'Reparatur', pictureUrl: image4_12},
    {id: 13, text: 'Finanzen, Steuern', pictureUrl: image4_13},
    {id: 14, text: 'Fahrstunden', pictureUrl: image4_14},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question4 = {id: 4, text: text4, items: items4};

// Question 5
const text5 = 'Freizeit';
const items5 = [
    {id: 1, text: 'Bewegung/Kreativität', pictureUrl: image5_1},
    {id: 2, text: 'Ausgang / Shoppen', pictureUrl: image5_2},
    {id: 3, text: 'Kommunikation', pictureUrl: image5_3},
    {id: 4, text: 'Entspannung/Unterhaltung', pictureUrl: image5_4}
];
const question5 = {id: 5, text: text5, items: items5};

// Question 6
const text6 = 'Bewegung/Kreativität';
const items6 = [
    {id: 1, text: 'Sport/Bewegung', pictureUrl: image6_1},
    {id: 2, text: 'Kunst/Musik', pictureUrl: image6_2},
];
const question6 = {id: 6, text: text6, items: items6};

// Question 7
const text7 = 'Sport';
const items7 = [
    {id: 1, text: 'Ballsport', pictureUrl: image7_1},
    {id: 2, text: 'Joggen', pictureUrl: image7_2},
    {id: 3, text: 'Fitness', pictureUrl: image7_3},
    {id: 4, text: 'Wassersport', pictureUrl: image7_4},
    {id: 5, text: 'Tanzen', pictureUrl: image7_5},
    {id: 6, text: 'Kampfsport', pictureUrl: image7_6},
    {id: 7, text: 'Leichtathletik', pictureUrl: image7_7},
    {id: 8, text: 'Wintersport', pictureUrl: image7_8},
    {id: 9, text: 'Radfahren / Mountainbike', pictureUrl: image7_9},
    {id: 10, text: 'Skaten', pictureUrl: image7_10},
    {id: 11, text: 'Spiele', pictureUrl: image7_11},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question7 = {id: 7, text: text7, items: items7};

// Question 8
const text8 = 'Kunst/Musik';
const items8 = [
    {id: 1, text: 'Instrument üben / Singen', pictureUrl: image8_1},
    {id: 2, text: 'Malen / Zeichnen', pictureUrl: image8_2},
    {id: 3, text: 'Basteln', pictureUrl: image8_3},
    {id: 4, text: 'Handarbeit', pictureUrl: image8_4},
    {id: 5, text: 'Theater', pictureUrl: image8_5},
    {id: 6, text: 'Makeup ausprobieren', pictureUrl: image8_6},
    {id: 7, text: 'Frisieren', pictureUrl: image8_7},
    {id: 8, text: 'Social Media Content kreieren', pictureUrl: image8_8},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question8 = {id: 8, text: text8, items: items8};

// Question 9
const text9 = 'Ausgang / Shoppen';
const items9 = [
    {id: 1, text: 'Shoppen', pictureUrl: image9_1},
    {id: 2, text: 'Ausgang', pictureUrl: image9_2},
];
const question9 = {id: 9, text: text9, items: items9};

// Question 10
const text10 = 'Shoppen';
const items10 = [
    {id: 1, text: 'Kleider kaufen', pictureUrl: image10_1},
    {id: 2, text: 'Schuhe kaufen', pictureUrl: image10_2},
    {id: 3, text: 'Kosmetik kaufen', pictureUrl: image10_3},
    {id: 4, text: 'Games und Zubehör kaufen', pictureUrl: image10_4},
    {id: 5, text: 'Möbel kaufen', pictureUrl: image10_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question10 = {id: 10, text: text10, items: items10};

// Question 11
const text11 = 'Ausgang';
const items11 = [
    {id: 1, text: 'Feiern', pictureUrl: image11_1},
    {id: 2, text: 'in Bar trinken', pictureUrl: image11_2},
    {id: 3, text: 'im Restaurant/Café essen/trinken', pictureUrl: image11_3},
    {id: 4, text: 'Shisha Bar besuchen', pictureUrl: image11_4},
    {id: 5, text: 'Konzert besuchen', pictureUrl: image11_5},
    {id: 6, text: 'Kino / Theater besuchen', pictureUrl: image11_6},
    {id: 7, text: 'Bowlen', pictureUrl: image11_7},
    {id: 8, text: 'Billard spielen', pictureUrl: image11_8},
    {id: 9, text: 'Laser Tag', pictureUrl: image11_9},
    {id: 10, text: 'Escape Room', pictureUrl: image11_10},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question11 = {id: 11, text: text11, items: items11};

// Question 12
const text12 = 'Kommunikation';
const items12 = [
    {id: 1, text: 'Verbale Kommunikation', pictureUrl: image12_1},
    {id: 2, text: 'Nonverbale Kommunikation', pictureUrl: image12_2},
];
const question12 = {id: 12, text: text12, items: items12};

// Question 13
const text13 = 'Verbale Kommunikation';
const items13 = [
    {id: 1, text: 'Reden', pictureUrl: image13_1},
    {id: 2, text: 'Chatten', pictureUrl: image13_2},
    {id: 3, text: 'Telefonieren', pictureUrl: image13_3},
    {id: 4, text: 'FaceTime', pictureUrl: image13_4},
    {id: 5, text: 'Streiten', pictureUrl: image13_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question13 = {id: 13, text: text13, items: items13};

// Question 14
const text14 = 'Nonverbale Kommunikation';
const items14 = [
    {id: 1, text: 'Umarmen', pictureUrl: image14_1},
    {id: 2, text: 'Kuscheln', pictureUrl: image14_2},
    {id: 3, text: 'Küssen', pictureUrl: image14_3},
    {id: 4, text: 'Händchen halten', pictureUrl: image14_4},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question14 = {id: 14, text: text14, items: items14};

// Question 15
const text15 = 'Entspannung/Unterhaltung';
const items15 = [
    {id: 1, text: 'Entspannung', pictureUrl: image15_1},
    {id: 2, text: 'Unterhaltung', pictureUrl: image15_2},
];
const question15 = {id: 15, text: text15, items: items15};

// Question 16
const text16 = 'Entspannung';
const items16 = [
    {id: 1, text: 'Meditation', pictureUrl: image16_1},
    {id: 2, text: 'Yoga', pictureUrl: image16_2},
    {id: 3, text: 'Lesen', pictureUrl: image16_3},
    {id: 4, text: 'Chillen', pictureUrl: image16_4},
    {id: 5, text: 'Musik hören', pictureUrl: image16_5},
    {id: 6, text: 'Podcast hören', pictureUrl: image16_6},
    {id: 7, text: 'Journaling', pictureUrl: image16_7},
    {id: 8, text: 'Rauchen', pictureUrl: image16_8},
    {id: 9, text: 'Snusen', pictureUrl: image16_9},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question16 = {id: 16, text: text16, items: items16};

// Question 17
const text17 = 'Unterhaltung';
const items17 = [
    {id: 1, text: 'Social Media', pictureUrl: image17_1},
    {id: 2, text: 'Fernsehen (Netflix)', pictureUrl: image17_2},
    {id: 3, text: 'YouTube Videos schauen', pictureUrl: image17_3},
    {id: 4, text: 'Gamen', pictureUrl: image17_4},
    {id: 5, text: 'Rätsel lösen', pictureUrl: image17_5},
    {id: 6, text: 'Gesellschaftsspiele spielen', pictureUrl: image17_6},
    {id: 7, text: 'Chatten', pictureUrl: image17_7},
    {id: 8, text: 'Googeln/ Im Internet recherchieren', pictureUrl: image17_8},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question17 = {id: 17, text: text17, items: items17};

// Question 18
const text18 = 'Essen/Schlafen/Körperpflege';
const items18 = [
    {id: 1, text: 'Essen/Trinken/Rauchen', pictureUrl: image18_1},
    {id: 2, text: 'Schlafen', pictureUrl: image18_2},
    {id: 3, text: 'Körperpflege', pictureUrl: image18_3}
];
const question18 = {id: 18, text: text18, items: items18};

// Question 19
const text19 = 'Essen/Trinken';
const items19 = [
    {id: 1, text: 'Frühstücken', pictureUrl: image19_1},
    {id: 2, text: 'Mittagessen', pictureUrl: image19_2},
    {id: 3, text: 'Abendessen', pictureUrl: image19_3},
    {id: 4, text: 'Snacken', pictureUrl: image19_4},
    {id: 5, text: 'Trinken', pictureUrl: image19_5},
    {id: 6, text: 'Rauchen', pictureUrl: image19_6},
    {id: 7, text: 'Snusen', pictureUrl: image19_7},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question19 = {id: 19, text: text19, items: items19};

// Question 20
const text20 = 'Schlafen';
const items20 = [
    {id: 1, text: 'Nickerchen', pictureUrl: image20_1},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question20 = {id: 20, text: text20, items: items20};

// Question 21
const text21 = 'Körperpflege';
const items21 = [
    {id: 1, text: 'Zahnpflege', pictureUrl: image21_1},
    {id: 2, text: 'Duschen/Baden', pictureUrl: image21_2},
    {id: 3, text: 'Haarpflege', pictureUrl: image21_3},
    {id: 4, text: 'Gesichtspflege', pictureUrl: image21_4},
    {id: 5, text: 'Körperhaarentfernung', pictureUrl: image21_5},
    {id: 6, text: 'Anziehen / Stylen', pictureUrl: image21_6},
    {id: 7, text: 'Haar stylen', pictureUrl: image21_7},
    {id: 8, text: 'Makeup', pictureUrl: image21_8},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const question21 = {id: 21, text: text21, items: items21};

// List of questions
const activityQuestionsList = [question1, question2, question3, 
    question4, question5, question6, question7, 
    question8, question9, question10, question11, 
    question12, question13, question14, question15,
    question16, question17, question18, question19, question20,
    question21
];


// Question 0
const personText0 = 'Ist noch jemand weiteres bei dir?';
const personItems0 = [
    {id: 1, text: 'Ja', pictureUrl: truePic},
    {id: 0, text: 'Nein', pictureUrl: falsePic}
];
const personQuestion0 = {id: 0, text: personText0, items: personItems0};

// Question 1
const personText1 = 'Mit wem bist du gerade?';
const personItems1 = [
    {id: 1, text: 'Kernfamilie', pictureUrl: person_image1_1},
    {id: 2, text: 'Freunde', pictureUrl: person_image1_2},
    {id: 3, text: 'Alleine', pictureUrl: person_image1_3},
    {id: 4, text: 'Verwandte', pictureUrl: person_image1_4},
    {id: 5, text: 'Nachbarn', pictureUrl: person_image1_5},
    {id: 6, text: 'Haustier', pictureUrl: person_image1_6},
    {id: 7, text: 'Arbeitgeber / Lehrperson', pictureUrl: person_image1_7},
    {id: 8, text: 'Fremde', pictureUrl: person_image1_8},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const personQuestion1 = {id: 1, text: personText1, items: personItems1};

// Question 2
const personText2 = 'Kernfamilie';
const personItems2 = [
    {id: 1, text: 'Mutter', pictureUrl: person_image2_1},
    {id: 2, text: 'Vater', pictureUrl: person_image2_2},
    {id: 3, text: 'Schwester', pictureUrl: person_image2_3},
    {id: 4, text: 'Bruder', pictureUrl: person_image2_4},
    {id: 5, text: 'Ehefrau', pictureUrl: person_image2_5},
    {id: 6, text: 'Ehemann', pictureUrl: person_image2_6},
    {id: 7, text: 'Kind', pictureUrl: person_image2_7},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const personQuestion2 = {id: 2, text: personText2, items: personItems2};

// Question 3
const personText3 = 'Freunde';
const personItems3 = [
    {id: 1, text: 'feste/r Freund/in', pictureUrl: person_image3_1},
    {id: 2, text: 'beste/r Freund/in', pictureUrl: person_image3_2},
    {id: 3, text: 'Freund/in', pictureUrl: person_image3_3},
    {id: 4, text: 'Schulkameraden', pictureUrl: person_images3_4},
    {id: 5, text: 'Arbeitskolleg/in', pictureUrl: person_image3_5},
    {id: 6, text: 'Online-Freund/in', pictureUrl: person_image3_6},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const personQuestion3 = {id: 3, text: personText3, items: personItems3};

// Question 4
const personText4 = 'Verwandte';
const personItems4 = [
    {id: 1, text: 'Grossmutter', pictureUrl: person_image4_1},
    {id: 2, text: 'Grossvater', pictureUrl: person_image4_2},
    {id: 3, text: 'Tante', pictureUrl: person_image4_3},
    {id: 4, text: 'Onkel', pictureUrl: person_image4_4},
    {id: 5, text: 'Cousin/e', pictureUrl: person_image4_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const personQuestion4 = {id: 4, text: personText4, items: personItems4};

// Question 5
const personText5 = 'Arbeitgeber / Lehrperson';
const personItems5 = [
    {id: 1, text: 'Arbeitgeber', pictureUrl: person_image5_1},
    {id: 2, text: 'Lehrperson', pictureUrl: person_image5_2},
    {id: 3, text: 'Berufsbildner/in', pictureUrl: person_image5_3},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const personQuestion5 = {id: 5, text: personText5, items: personItems5};

const personText6 = 'Wie viele solche Personen sind anwesend?';
const personItems6 = [
    {id: 1, text: 'Eine', pictureUrl: person_image6_1},
    {id: 2, text: 'Zwei', pictureUrl: person_image6_2},
    {id: 3, text: 'Drei', pictureUrl: person_image6_3},
    {id: 4, text: 'Vier', pictureUrl: person_image6_4},
    {id: 5, text: 'Fünf oder mehr', pictureUrl: person_image6_5},
];

const personQuestion6 = {id: 6, text: personText6, items: personItems6};



const personQuestionsList = [personQuestion1, personQuestion2, personQuestion3,
    personQuestion4, personQuestion5, personQuestion6
];

// Question 1
const placeText1 = 'Wo bist du gerade?';
const placeItems1 = [
    {id: 1, text: 'Zu Hause', pictureUrl: place_image1_1},
    {id: 2, text: 'Bei jemandem zu Hause', pictureUrl: place_image1_2},
    {id: 3, text: 'Sportplatz/Sporthalle', pictureUrl: place_image1_3},
    {id: 4, text: 'In der Natur: Park, Wald etc.', pictureUrl: place_image1_4},
    {id: 5, text: 'In der Stadt/im Dorf', pictureUrl: place_image1_5},
    {id: 6, text: 'Institutionen: Jugendtreff, Tagi, Schule, Kirchgruppe', pictureUrl: place_image1_6},
    {id: 7, text: 'Gruppen Treffpunkt', pictureUrl: place_image1_7},
    {id: 8, text: 'Im ÖV / Auto', pictureUrl: place_image1_8},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const placeQuestion1 = {id: 1, text: placeText1, items: placeItems1};

// Question 2
const placeText2 = 'Bei jemandem zu Hause: Bei wem?';
const placeItems2 = [
    {id: 1, text: 'Elternteil', pictureUrl: place_image2_1},
    {id: 2, text: 'Freunde', pictureUrl: place_image2_2},
    {id: 3, text: 'Verwandte', pictureUrl: place_image2_3},
    {id: 4, text: 'Nachbarn', pictureUrl: place_image2_4},
    {id: 5, text: 'Arbeitgeber / Lehrperson', pictureUrl: place_image2_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const placeQuestion2 = {id: 2, text: placeText2, items: placeItems2};

// Question 3
const placeText3 = 'Freunde';
const placeItems3 = [
    {id: 1, text: 'feste/r Freund/in', pictureUrl: place_image3_1},
    {id: 2, text: 'beste/r Freund/in', pictureUrl: place_image3_2},
    {id: 3, text: 'Freund/in', pictureUrl: place_image3_3},
    {id: 4, text: 'Schulkameraden', pictureUrl: place_image3_4},
    {id: 5, text: 'Arbeitskolleg/in', pictureUrl: place_image3_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const placeQuestion3 = {id: 3, text: placeText3, items: placeItems3};

// Question 4
const placeText4 = 'Verwandte';
const placeItems4 = [
    {id: 1, text: 'Grossmutter', pictureUrl: place_image4_1},
    {id: 2, text: 'Grossvater', pictureUrl: place_image4_2},
    {id: 3, text: 'Tante', pictureUrl: place_image4_3},
    {id: 4, text: 'Onkel', pictureUrl: place_image4_4},
    {id: 5, text: 'Cousin/e', pictureUrl: place_image4_5},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const placeQuestion4 = {id: 4, text: placeText4, items: placeItems4};

// Question 5
const placeText5 = 'Arbeitgeber / Lehrperson';
const placeItems5 = [
    {id: 1, text: 'Arbeitgeber', pictureUrl: place_image5_1},
    {id: 2, text: 'Lehrperson', pictureUrl: place_image5_2},
    {id: 3, text: 'Berufsbildner/in', pictureUrl: place_image5_3},
    {id: 0, text: 'Andere', pictureUrl: otherPic}
];
const placeQuestion5 = {id: 5, text: placeText5, items: placeItems5};

const placeQuestionsList = [placeQuestion1, placeQuestion2, placeQuestion3,
    placeQuestion4, placeQuestion5
];

export { activityQuestionsList };
export { personQuestionsList };
export { placeQuestionsList };