const activityQuestionMapping = {
    // Example structure: 'questionId-itemId': nextQuestionNumber
    '1-1': 2,
    '1-2': 5,
    '1-3': 18,
    '2-1': 3,
    '2-2': 4,
    '5-1': 6,
    '5-2': 9,
    '5-3': 12,
    '5-4': 15,
    '6-1': 7,
    '6-2': 8,
    '9-1': 10,
    '9-2': 11,
    '12-1': 13,
    '12-2': 14,
    '15-1': 16,
    '15-2': 17,
    '18-1': 19,
    '18-2': 20,
    '18-3': 21,
    // Add more mappings as required
};

const personQuestionMapping = {
    // Example structure: 'questionId-itemId': nextQuestionNumber
    '1-1': 2,
    '1-2': 3,
    '1-4': 4,
    '1-7': 5,
    '2-3': 6,
    '2-4': 6,
    '3-3': 6,
    '3-4': 6,
    '3-5': 6,
    '4-5': 6,
    // Add more mappings as required
};

const placeQuestionMapping = {
    // Example structure: 'questionId-itemId': nextQuestionNumber
    '1-2': 2,
    '2-2': 3,
    '2-3': 4,
    '2-5': 5,
    // Add more mappings as required
};


export { activityQuestionMapping };
export { personQuestionMapping };
export { placeQuestionMapping };