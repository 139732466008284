import React, { useEffect, useRef, useState } from 'react';
import './Timetable.css'; // Ensure you have appropriate CSS styles
import HourComponent from './HourComponent.tsx'; // Import your HourComponents
import html2canvas from 'html2canvas';


const Timetable = ({ schedule }) => {
    const [capturedImage, setCapturedImage] = useState<string | null>(null);
    const timetableRef = useRef(null);
    const captureTimetable = () => {
        const timetableElement = timetableRef.current;
        if (timetableElement) {
            html2canvas(timetableElement).then((canvas) => {
                // Create an image from the canvas
                const image = canvas.toDataURL('image/png');
                // For example, open the image in a new tab
                setCapturedImage(image); 
            });
        }
    };
    const convertTimeToMinutes = (timeString) => {
            if (!timeString) return null;
            const [hours, minutes] = timeString.split(':').map(Number);
            return hours * 60 + minutes;
    };

    const convertMinutesToTime = (totalMinutes) => {
        if (totalMinutes == null) return null;
        const hours = Math.floor(totalMinutes / 60);
        const minutes = totalMinutes % 60;
    
        // Format hours and minutes to always be two digits
        const formattedHours = hours.toString().padStart(2, '0');
        const formattedMinutes = minutes.toString().padStart(2, '0');
    
        return `${formattedHours}:${formattedMinutes}`;
    };
    

      // Placeholder for function to adjust free hour
      const adjustFreeHour = (day, hour) => {
        console.log(`Adjusting free hour for ${day} at ${hour}`);
      };
        const days = ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'];
        const hours = Array.from({ length: 24 }, (_, index) => `${index.toString().padStart(2, '0')}:00`);        
        
        // Function to render hours for a given day
    const renderHoursForDay = (day) => {
        return hours.map((hour, index) => {
            const [hourStart,] = hour.split(':').map(Number); // Get the start hour as a number
            const hourMinutesStart = hourStart * 60; // Start time of the hour block in minutes
            const hourMinutesEnd = hourMinutesStart + 59; // End time of the hour block in minutes
    
            const daySchedule = schedule[day] || {};
            const wakeUpTime = convertTimeToMinutes(daySchedule['wakeUpTime']);
            // Similar for other times: schoolStartTime, lunchTime, etc.
            // Determine backgroundColor and description based on the logic you provided
            let description = ''; // Logic to set this based on conditions
            let backgroundColor = ''; // Logic to set this based on conditions

            // Logic to determine `description` and `backgroundColor`
            // Similar for other times: schoolStartTime, lunchTime, etc.
            const schoolStartTime = convertTimeToMinutes(schedule[day]['schoolStartTime']) || null;
            const lunchTime = convertTimeToMinutes(schedule[day]['lunchTime']) || null;
            const schoolEndTime = convertTimeToMinutes(schedule[day]['schoolEndTime']) || null;
            const sleepTime = convertTimeToMinutes(schedule[day]['sleepTime']) || null;

            // Flag to determine if the hour is free
            let isFreeHour = true;


            // WakeUpTime within the hour block
            if (wakeUpTime != null && wakeUpTime >= hourMinutesStart && wakeUpTime <= hourMinutesEnd) {
                description += ' Aufwachzeit';
                description += "\n(" + daySchedule['wakeUpTime'] + ")";
                backgroundColor = '#FFFF00'; // Specific color for wake up time
                isFreeHour = false;
            }

            // SchoolStartTime within the hour block
            if (schoolStartTime != null && schoolStartTime >= hourMinutesStart && schoolStartTime <= hourMinutesEnd) {
                description += ' Schulbeginn';
                description += "\n(" + daySchedule['schoolStartTime'] + ")";
                backgroundColor = '#FDF4FE'; // Specific color for school start time
                isFreeHour = false;
            }

            // LunchTime within the hour block
            if (lunchTime != null && lunchTime >= hourMinutesStart && lunchTime <= hourMinutesEnd) {
                description += ' Mittagspause';
                description += "\n(" + daySchedule['lunchTime'] + ")";
                backgroundColor = '#FFFACD'; // Specific color for lunch time
                isFreeHour = false;
            }

            // SchoolEndTime within the hour block
            if (schoolEndTime != null && schoolEndTime >= hourMinutesStart && schoolEndTime <= hourMinutesEnd) {
                description += ' Schul-/Arbeitsschluss';
                description += "\n(" + daySchedule['schoolEndTime'] + ")";
                backgroundColor = '#FDF4FE'; // Specific color for school end time
                isFreeHour = false;
            }

            // SleepTime within the hour block
            if (sleepTime != null && sleepTime >= hourMinutesStart && sleepTime <= hourMinutesEnd) {
                description += ' Schlafenszeit';
                description += "\n(" + daySchedule['sleepTime'] + ")";
                backgroundColor = '#FFFF00'; // Specific color for sleep time
                isFreeHour = false;
            }

            if (day != 'Montag') {
                const dayIndex = days.indexOf(day);
                const previousDay = days[dayIndex - 1];
                const previousDaySchedule = schedule[previousDay] || {};
                let previousDaySleepTime = convertTimeToMinutes(previousDaySchedule['sleepTime']) || null;
                if (previousDaySleepTime > 1440) {
                    previousDaySleepTime = previousDaySleepTime - 1440;
                    if (previousDaySleepTime != null && previousDaySleepTime >= hourMinutesStart && previousDaySleepTime <= hourMinutesEnd) {
                        if (previousDaySleepTime >= hourMinutesStart && previousDaySleepTime <= hourMinutesEnd) {
                            description += ' Schlafenszeit';
                            description += "\n(" + convertMinutesToTime(previousDaySleepTime) + ")";
                            backgroundColor = '#FFFF00'; // Specific color for sleep time
                            isFreeHour = false;
                        }
                    }
                }
            }


            // Check for freeHours within the hour block
            daySchedule.freeHours.forEach(freeHour => {
                const freeHourStart = convertTimeToMinutes(freeHour.start);
                const freeHourEnd = convertTimeToMinutes(freeHour.end);
                if ((freeHourStart >= hourMinutesStart && freeHourStart <= hourMinutesEnd) || 
                    (freeHourEnd >= hourMinutesStart && freeHourEnd <= hourMinutesEnd) || 
                    (freeHourStart <= hourMinutesStart && freeHourEnd >= hourMinutesEnd)) {
                    // This means the freeHour falls within the current hour block
                    description = `Zwischenstunde`;
                    description += `\n(${freeHour.start} - ${freeHour.end})`;
                    backgroundColor = '#F4FFF0'; // Set a specific color for freeHours
                    isFreeHour = false;
                }
            });


            // Check for activities within the hour block
            daySchedule.activities.forEach(activity => {
                const activityStart = convertTimeToMinutes(activity.startTime);
                const activityEnd = convertTimeToMinutes(activity.endTime);
                if ((activityStart >= hourMinutesStart && activityStart <= hourMinutesEnd) || 
                    (activityEnd >= hourMinutesStart && activityEnd <= hourMinutesEnd) || 
                    (activityStart <= hourMinutesStart && activityEnd >= hourMinutesEnd)) {
                    // This means the activity falls within the current hour block
                    description = ` ${activity.activityName}`;
                    description += `\n(${activity.startTime} - ${activity.endTime})`;
                    backgroundColor = '#FFD6D7'; // Set a specific color for activities
                    isFreeHour = false;
                }
            });

            

            if (isFreeHour) {
                return null; 
            }


            return (
                <HourComponent
                    key={index}
                    hour={hour}
                    onClick={() => adjustFreeHour(day, hour)}
                    description={description}
                    backgroundColor={backgroundColor}
                    selected={false} // Adjust according to your needs
                />
            );
        }).filter(component => component !== null);;
    };

    useEffect(() => {
        captureTimetable();
    }, []);


    return (
        <div>
        <div className="Timetable" ref={timetableRef}>
            {!capturedImage && days.map((day) => (
                <div key={day} className="daySchedule">
                    <h2 className="dayName">{day}</h2>
                    <div className="hoursContainer">
                        {renderHoursForDay(day)}
                    </div>
                </div>
            ))}
        </div>
        {capturedImage && (
                <div id='timetable-image'>
                    <img src={capturedImage} alt="Timetable" />
                </div>
            )}
        </div>
    ); 
};

export default Timetable;
