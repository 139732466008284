const HourComponent = ({ hour, onClick, selected, description, backgroundColor }) => {
  // Apply a red background if isSchoolSession is true, else use the provided backgroundColor
  const style = { 
    backgroundColor: backgroundColor 
  };
  
  return (
    <div className={`hour-block ${selected ? 'selected' : ''}`} style={style} onClick={() => onClick(hour)}>
      {hour} <br />{description}
    </div>
  );
};


export default HourComponent;